import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

/**
 *  관리자 화면 채용 - 입사지원서 양식 관리 기능 클래스
 */
class RecForm {

    /**
     * 생성자
     */
    constructor() {
        this.formNo = 1;
        this.useYn = 'Y';
        this.currentPage = 1;
        this.listSize = 2147483647;
        this.gridTopSize = 15;
    }

    /**
     * 입사지원서 양식 목록 조회
     * @param {string} useYn 양식 사용여부
     */
    getRecForms(useYn, CARR_DIV_CD) {
        return new Promise((resolve, reject) => {

            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz';
            request.methodId = 'GetFormMaster';
            request.parameters.useYN = useYn;
            request.parameters.CARR_DIV_CD = CARR_DIV_CD;

            service.execute(request).then(response => {

                const tables = response.data.result.value;

                if (!!tables) resolve(tables);
                else resolve(null);
            }).catch(error => reject(error));
        });
    };
}

export const recForm = new RecForm();