import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

/**
 * 공지사항 화면 관련 기능 클래스
 */
class Notice {
    /**
     * 생성자
     */
    constructor() {
        this.systemCode = process.env.VUE_APP_SYSTEM_CODE;
        this.boardCategory = 'KRTCM001';
        this.currentPage = 1;
        this.listSize = 2147483647; // [주의] 쿼리 수정 하면 안됨. top size = int.max
        this.gridTopSize = 15;      // [주의] 쿼리 상의 top size 와 다름. 이것은 그리드용 top size.
    }

    /**
     * 게시판 정보 가져오기     
     * @param string boardCategory 
     * @returns 
     */
    getBoardInfo(boardCategory) {
        return new Promise((resolve, reject) => {
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Common.Biz.BoardInfoBiz';
            request.methodId = 'GetBoardInfo';
            request.parameters.systCode = this.systemCode;
            //request.parameters.boardCategory = this.boardCategory;   
            request.parameters.boardCategory = boardCategory;  // as-is same.. querystring["boardcategory"]

            service.execute(request).then(response => {
                const rows = response.data.result.value.Table.rows;

                if (!!rows.length) resolve(rows[0]);
                else resolve(null);
            }).catch(error => reject(error));
        });
    };

    /**
     * 공지사항 목록 조회
     * @param object boardInfo : 게시판 정보
     * @returns 
     */
    getNotices = (boardInfo) => {
        const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
        const service = new FoxRestBizClient(url);
        const request = new FoxBizRequest();
        request.classId = 'Site.Koreit.Biz.KRTBoardBiz';
        request.methodId = 'GetKRTBoardContentList';
        request.parameters["dic"] = JSON.stringify(boardInfo);

        return new Promise((resolve, reject) => {
            service.execute(request).then(response => {
                const resultData = response.data.result.value;
                const notices = resultData.Table.rows;

                notices.forEach(notice => {
                    // [check-this] 검토 필요

                    // 구분 값 설정
                    if (boardInfo.Code !== 0) notice.DisplayCodeName = notice.CodeName;
                    else notice.DisplayCodeName = "";

                    // 공지사항 제목 설정
                    if (boardInfo.IsComment === 'Y') notice.DisplayTitle = `[${notice.CodeName}] ${notice.Title} (${notice.CommentCnt})`;
                    else notice.DisplayTitle = notice.Title;

                    // 게시기간 설정
                    if (!!notice.StartpiredDt && !!notice.ExpiredDt) notice.PostingPeriod = `${notice.StartpiredDt} ~ ${notice.ExpiredDt}`;
                });

                resolve({
                    notices: resultData.Table.rows,
                    pageInfo: resultData.Table1.rows,
                })
            }).catch(error => reject(error));
        });
    };

    /**
     * # Get Board Detail Datas
     * @param serialized-string dic 
     * @returns 
     */
    getNoticeDetail(dic) {
        const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
        const service = new FoxRestBizClient(url);
        const request = new FoxBizRequest();
        request.classId = 'Site.Koreit.Biz.KRTBoardBiz';
        request.methodId = 'GetKRTBoardContent';
        request.parameters["dic"] = JSON.stringify(dic);

        return new Promise((resolve, reject) => {
            var sf = function (res) {
                var ret = new Object(), dt0 = res.data.result.value.Table.rows, dr0 = {};
                if (dt0 && dt0.length) {
                    dr0 = dt0[0];

                    // [check-this] "게시기간" like as-is
                    if (dr0.ExpiredDt != "" && dr0.ExpiredDt != null) {
                        var str = dr0.StartpiredDt != undefined && dr0.StartpiredDt != "" ? dr0.StartpiredDt : "없음";
                        var end = dr0.ExpiredDt != undefined && dr0.ExpiredDt != "" ? dr0.ExpiredDt : "없음";
                        dr0["PiredDt"] = `${str} ~ ${end}`;
                    } else {
                        dr0["PiredDt"] = "영구게시";
                    }
                    // [check-this] "상단글 고정기간" like as-is
                    if (dr0.IsAnnounce === "Y") {
                        var str = dr0.StartAnnounceDt != undefined && dr0.StartAnnounceDt != "" ? dr0.StartAnnounceDt : "없음";
                        var end = dr0.AnnounceDt != undefined && dr0.AnnounceDt != "" ? dr0.AnnounceDt : "없음";                        
                        dr0.DispAnnounceDt = `${str} ~ ${end}`;
                    } else {
                        dr0.DispAnnounceDt = "비활성화";
                    }
                }
                ret["articles"] = dr0;                                  // 게시글
                ret["attFiles"] = res.data.result.value.Table1.rows;    // 첨부파일
                ret["boardImg"] = res.data.result.value.Table2.rows;    // 콘텐츠이미지
                ret["thumbImg"] = res.data.result.value.Table3.rows;    // 썸네일 이미지
                resolve(ret);
            };
            service.execute(request).then(sf).catch(error => reject(error));
        });
    };

    /**
     * # Get Board Prev & Next Data
     * @param serialized-string dic 
     * @returns 
     */
    getKRTBoardContentPreNext(dic) {
        const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
        const service = new FoxRestBizClient(url);
        const request = new FoxBizRequest();
        request.classId = 'Site.Koreit.Biz.KRTBoardBiz';
        request.methodId = 'GetKRTBoardContentPreNext';
        request.parameters["dic"] = JSON.stringify(dic);

        return new Promise((resolve, reject) => {
            var sf = function (res) {
                var ret = new Object();
                ret["prevData"] = res.data.result.value.Table.rows;    // 이전글 (as-is 의심스럽다)
                ret["nextData"] = res.data.result.value.Table1.rows;   // 다음글 (as-is 의심스럽다)
                resolve(ret);
            };
            service.execute(request).then(sf).catch(error => reject(error));
        });
    };

    /**
     * # Get Board Comment Data
     * @param serialized-string dic 
     * @returns 
     */
    getBoardCommentList(dic) {
        const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
        const service = new FoxRestBizClient(url);
        const request = new FoxBizRequest();
        request.classId = "Site.Common.Biz.BoardBiz";
        request.methodId = "GetBoardCommentList";
        request.parameters["dic"] = JSON.stringify(dic);

        return new Promise((resolve, reject) => {
            var sf = function (res) {
                var ret = new Object();
                ret["cmmtData"] = res.data.result.value.Table.rows;
                resolve(ret);
            };
            service.execute(request).then(sf).catch(error => reject(error));
        });
    };

    /**
     * # Remove Board Comment Data
     * @param serialized-string dic 
     * @returns 
     */
    deleteBoardComment(dic) {
        const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
        const service = new FoxRestBizClient(url);
        const request = new FoxBizRequest();
        request.classId = "Site.Common.Biz.BoardBiz";
        request.methodId = "DeleteBoardComment";
        request.parameters["dic"] = JSON.stringify(dic);

        return new Promise((resolve, reject) => {
            service.execute(request).then(() => { resolve({ result: true }); }).catch(error => reject(error));
        });
    };

    /**
     * # Save Board Comment Data
     * @param serialized-string dic 
     * @returns 
     */
    saveBoardComment(dic) {
        const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
        const service = new FoxRestBizClient(url);
        const request = new FoxBizRequest();
        request.classId = "Site.Common.Biz.BoardBiz";
        request.methodId = "SaveBoardComment";
        request.parameters["dic"] = JSON.stringify(dic);

        return new Promise((resolve, reject) => {
            service.execute(request).then(() => { resolve({ result: true }); }).catch(error => reject(error));
        });
    };

    /**
     * # Remove Board Data
     * @param serialized-string dic 
     * @returns 
     */
    deleteBoardContent(dic) {
        const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
        const service = new FoxRestBizClient(url);
        const request = new FoxBizRequest();
        request.classId = "Site.Common.Biz.BoardBiz";
        request.methodId = "DeleteBoardContent";
        request.parameters["dic"] = JSON.stringify(dic);

        return new Promise((resolve, reject) => {
            service.execute(request).then(() => { resolve({ result: true }); }).catch(error => reject(error));
        });
    };

    /**
     * # Save Board Data
     * @param serialized-string dic 
     * @param serialized-string file 
     * @returns 
     */
    saveBoardContent(dic, file) {
        var url = process.env.VUE_APP_BIZ_SERVICE_REST_API, svc = new FoxRestBizClient(url), req = new FoxBizRequest();
        req.classId = "Site.Common.Biz.BoardBiz", req.methodId = "SaveBoardContent", req.parameters.dic = dic, req.parameters.file = file;
        return new Promise((resolve, reject) => {
            svc.execute(req).then(() => { resolve({ result: true }); }).catch((err) => reject(err));
        });
    }
}

export const notice = new Notice();