<template>
    <div class="admin-content">
        <h3 v-text="state.defaults.BoardName"></h3>
        <div class="flex-wrap right">
            <div>
                <div class="btn-wrap">
                    <button type="button" v-on:click="methods.board.list" class="btn-box">목록</button>
                    <button type="button" v-on:click="methods.board.cncl" class="btn-box basic">취소</button>
                    <button type="button" v-on:click="methods.board.bfre(); methods.board.save(!v$.$invalid)"
                            class="btn-box purple">저장</button>
                    <button type="button" v-on:click="methods.board.rmve" v-if="state.query.Post === 'U'"
                            class="btn-box red">삭제</button>
                </div>
            </div>
        </div>
        <div class="table-wrap table-list none-scroll-y mt50">
            <div class="table-box">
                <table>
                    <colgroup>
                        <col width="15%">
                        <col width="20%">
                        <col width="15%">
                        <col width="20%">
                        <col width="15%">
                        <col width="20%">
                    </colgroup>
                    <tbody>
                    <tr>
                        <th scope="row">제목 <i class="required">*</i></th>
                        <td colspan="6">
                            <InputText id="ctlTitle" class="w-100" v-model="v$.data.Title.$model"
                                       :class="{ 'p-invalid': v$.data.Title.$invalid && state.saved }" />
                            <br v-if="v$.data.Title.$invalid && state.saved" />
                            <label for="ctlTitle" v-if="v$.data.Title.$invalid && state.saved"
                                   :class="{ 'p-error': v$.data.Title.$invalid && state.saved }">제목을 입력하세요</label>
                        </td>
                    </tr>
                    <tr v-if="state.defaults.Code !== '0'">
                        <th scope="row" v-text="state.defaults.CodeName"></th>
                        <td colspan="6">
                            <div class="select-box">
                                <select v-model="state.data.CodeVal" @change="eventHandler.codeListChange()">
                                    <option v-for="code in state.codes" v-bind:value="code.CodeVal"
                                            v-bind:key="code" v-text="code.CodeName" />
                                </select>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="state.defaults.Code !== '0' && state.defaults.Code === 'CD021000'">
                        <th scope="row">입사지원서 양식</th>
                        <td colspan="6">
                            <div class="select-box">
                                <select v-model="state.data.FORM_NO">
                                    <option value="" selected disabled>선택</option>
                                    <option v-for="form in state.FormList" v-bind:value="form.FORM_NO"
                                            v-bind:key="form" v-text="form.FORM_NM" />
                                </select>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">게시기간</th>
                        <td colspan="6">
                            <div class="period-wrap">
                                <div>
                                    <input type="date" v-model="state.data.StartpiredDtYmd" class="input-text" max="9999-12-31"
                                           style="width:180px;" v-bind:disabled="state.data.Perpetual" />
                                </div>
                                <div>
                                    <input type="time" v-model="state.data.StartpiredDtHm" class="input-text"
                                           style="width:180px;" v-bind:disabled="state.data.Perpetual" />
                                </div>
                                <span>~</span>
                                <div>
                                    <input type="date" v-model="state.data.ExpiredDtYmd" class="input-text" max="9999-12-31"
                                           style="width:180px;" v-bind:disabled="state.data.Perpetual" />
                                </div>
                                <div>
                                    <input type="time" v-model="state.data.ExpiredDtHm" class="input-text"
                                           style="width:180px;" v-bind:disabled="state.data.Perpetual" />
                                </div>
                                <div class="chk-wrap">
                                    <div class="chk-box">
                                        <input type="checkbox" id="ctlPerpetual" :binary="true"
                                               v-model="state.data.Perpetual" />
                                        <label for="ctlPerpetual">
                                            <p>영구게시</p>
                                        </label>
                                    </div>
                                    <div class="chk-box">
                                        <input type="checkbox" id="ctlUseYn" :binary="true"
                                               v-model="state.data.UseYn" />
                                        <label for="ctlUseYn">
                                            <p>사용여부</p>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">상단글</th>
                        <td colspan="6">
                            <div class="period-wrap">
                                <div>
                                    <!--<p-inputtext type="text" v-model="value1"></p-inputtext>-->
                                    <input type="date" class="input-text" style="width:180px;"
                                           v-model="state.data.StartAnnounceDt"
                                           v-bind:disabled="!state.data.IsAnnounce" />
                                </div>
                                <span>~</span>
                                <div>
                                    <!--<p-inputtext type="text" v-model="value1"></p-inputtext>-->
                                    <input type="date" class="input-text mr10" style="width:180px;"
                                           v-model="state.data.AnnounceDt" v-bind:disabled="!state.data.IsAnnounce" />
                                </div>
                                <div class="chk-wrap">
                                    <div class="chk-box">
                                        <input type="checkbox" id="ctlIsAnnounce" :binary="true"
                                               v-model="state.data.IsAnnounce" />
                                        <label for="ctlIsAnnounce">
                                            <p>상단글 고정</p>
                                        </label>
                                    </div>
                                    <div class="chk-box">
                                        <input type="checkbox" id="ctlSoloNotice" :binary="true"
                                               v-bind:disabled="!state.data.UseYn" v-model="state.data.SoloNotice" />
                                        <label for="ctlSoloNotice">
                                            <p>메인한줄 공지글</p>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">내용 <i class="required">*</i></th>
                        <td colspan="6">
                            <br v-if="v$.data.Content.$invalid && state.saved" />
                            <label for="ctlContent" v-if="v$.data.Content.$invalid && state.saved"
                                   :class="{ 'p-error': v$.data.Content.$invalid && state.saved }">내용을 입력하세요</label>
                            <SmartEditor ref="smartEditor" :contents="state.Content"/>
                        </td>
                    </tr>
                    <tr v-if="state.defaults.AttFileCount > 0">
                        <th scope="row">파일첨부</th>
                        <td colspan="6">
                            <FileUploader ref="fileUploader1" :subUrl="store.getters.boardSubDirectoryPath" @attachments="state.attFiles"
                                          :maxFileCount="state.defaults.AttFileCount" :attachments="state.attFiles" />
                        </td>
                    </tr>
                    <!-- [check-this] '컨텐츠 이미지' as-is 상세 => 주석O, 입력은 => 주석X /  IsContent = "Y" && IsThumb = "N" -->
                    <!-- [check-this] '컨텐츠 이미지' 사용하지 않는것으로 판단... 입력모드 ... 제거 ....-->
                    <tr v-if='state.defaults.UseThumbImg === "Y"'>
                        <th>섬네일</th>
                        <td colspan="6">
                            <FileUploader ref="fileUploader2" :subUrl="store.getters.boardSubDirectoryPath" @attachments="state.thumbImg"
                                          :maxFileCount="state.defaults.AttFileCount" :attachments="state.thumbImg" />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive } from 'vue';
import { toRaw, ref } from '@vue/reactivity'
import { useStore } from 'vuex';
import { email, minLength, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { onMounted, watch } from '@vue/runtime-core';
import { useRoute } from "vue-router";
import { notice } from '@/modules/site/PCM_SM0810R.js';
import { common } from '@/modules/common/common.js';
import { recForm } from '@/modules/recruit/REC_RC0100R.js';
import SmartEditor from '@/components/SmartEditor.vue';
import FileUploader from '@/components/FileUploader.vue';
import alertManager from '@/utils/alert-confirm-manager';
import router from '@/router'
import { ConcatSource } from 'webpack-sources';
import { stat } from 'fs';
import he from 'he';

export default ({
    components: { SmartEditor, FileUploader },
    setup() {
        const _vrt = useRoute();
        const _userInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
        const store = useStore();
        const fileUploader1 = ref(null), fileUploader2 = ref(null);
        const smartEditor = ref(null);
        var state = reactive({
            defaults: {},
            query: _vrt.query,
            codes: new Array(),
            saved: false,
            data: {
                SystCode: notice.systemCode,
                Seq: "0",
                BoardCategory: _vrt.query.boardcategory,
                GroupNo: _vrt.query.Post === "R" ? _vrt.query.ParentNo : "0",       // [check-this] 답글.. sp 문제 같은디..
                DepthNo: _vrt.query.Post === "R" ? "2" : "1",
                ParentNo: _vrt.query.Post === "R" ? _vrt.query.ParentNo : "0",
                Title: _vrt.query.Post === "R" ? "RE: " : "",
                Content: "",
                CodeVal: "",
                UseYn: true,
                IsAnnounce: false,
                Perpetual: true,
                SoloNotice: false,
                SoloBoard: "",                  // # no use... but exist in sp...
                LatArea: "",                    // # no use... but exist in sp...
                LngArea: "",                    // # no use... but exist in sp...
                Email: "",                      // # no use... but exist in sp...
                StartpiredDt: null,
                StartpiredDtYmd: null,          // # 게시기간 Start 년월일
                StartpiredDtHm: null,           // # 게시기간 Start 시분               
                ExpiredDt: null,
                ExpiredDtYmd: null,             // # 게시기간 End 년월일
                ExpiredDtHm: null,              // # 게시기간 End 시분  
                StartAnnounceDt: null,
                AnnounceDt: null,
                UserNo: _userInfo.EmplID,
                RC_YEAR: "",
                RC_SEQ: 0,
                FORM_NO: 0,
            },
            attFiles: [],
            thumbImg: new Array(),
            Content:'',                         // 내용 바인딩
            FormList: []                        // 입사지원서 양식 리스트
        });
        const v$ = useVuelidate({
            data: {
                Title: { required },
                Content: { required }
            }
        }, state);
        /**
         * # Create Default Parameter for all service
         */
        var getParameter = function () {
            var prm = new Object();
            prm["Seq"] = _vrt.query.Seq;
            prm["BoardSeq"] = _vrt.query.Seq;
            prm["SystCode"] = notice.systemCode;
            prm["BoardCategory"] = _vrt.query.boardcategory;
            prm["GroupNo"] = "0";
            prm["UserId"] = _userInfo.EmplID;
            prm["Title"] = "";
            prm["Content"] = "";
            prm["UseYn"] = "";
            prm["UserNo"] = "";
            prm["CodeVal"] = "";
            prm["ExpiredDt"] = "";
            return prm;
        };
        const methods = {
            board: {
                /**
                 * # 목록
                 */
                list: function () {
                    // [check-this] as-is list stateless ... same !!
                    var q = _vrt.query, ks = ["Seq", "Post", "DepthNo", "ParentNo"];
                    ks.forEach((x) => delete q[x]);
                    router.push({ name: "PCM_SM0810R", query: q });
                },
                /**
                 * # 취소
                 */
                cncl: () => {
                    router.go(-1);
                },
                /**
                 * # I don't want this...
                 */
                bfre: () => {
                    let contentsEncode = he.escape(smartEditor.value.getContents());
                    contentsEncode = window.btoa(encodeURIComponent((contentsEncode)));
                    state.data.Content = contentsEncode;
                },
                /**
                 * # 저장
                 */
                save: function (valid) {

                    state.saved = !valid;
                    if (!valid) { return; }
                    state.saved = !state.saved;
                    state.data.UseYn = state.data.UseYn ? "Y" : "N";
                    state.data.IsAnnounce = state.data.IsAnnounce ? "Y" : "N";
                    state.data.SoloNotice = state.data.SoloNotice ? "Y" : "N";
                    state.data.RecruitYear = typeof state.data.RC_YEAR === 'string' || state.data.RC_YEAR === null ? state.data.RC_YEAR : state.data.RC_YEAR.getFullYear()
                    if (state.data.StartpiredDtHm == null) state.data.StartpiredDtHm = "00:00"
                    if (state.data.ExpiredDtHm == null) state.data.ExpiredDtHm = "23:59"
                    if (state.data.StartpiredDtYmd != null && state.data.StartpiredDtHm != null) state.data.StartpiredDt = `${state.data.StartpiredDtYmd} ${state.data.StartpiredDtHm}`;
                    if (state.data.ExpiredDtYmd != null && state.data.ExpiredDtHm != null) state.data.ExpiredDt = `${state.data.ExpiredDtYmd} ${state.data.ExpiredDtHm}`;

                    // 추가 validation
                    if(state.data.StartpiredDtYmd > state.data.ExpiredDtYmd) {
                        alertManager.alert.warning("게시시작일을 게시종료일보다 이전 날짜로 입력해 주십시오.");
                        return false;
                    }

                    // 채용공고일 경우
                    if(_vrt.query.boardcategory == 'RECCM001'){

                        if(state.data.FORM_NO == "") {
                            alertManager.alert.warning("입사지원서 양식을 선택해 주십시오.");
                            return false;
                        }
                    }else{
                        state.data.FORM_NO = null;
                        state.data.RC_SEQ = null;
                        state.data.RC_YEAR = null;
                    }

                    var sf = async function (x) {
                        if (x) {

                            // 통합 파일 정보
                            let ff = [];
                            // 첨부파일 정보
                            let ff1 = [];
                            // 섬네일 파일 정보
                            let ff2 = [];

                            // 첨부파일
                            if (state.defaults.AttFileCount > 0) {
                                var f1 = await fileUploader1.value.uploadFiles();
                                var ofile = state.attFiles.map((x) => {
                                    return {
                                        FileSeq: x.FileSeq, FileNm: x.FileNm, FilePath: x.FilePath, FileSize: x.FileSize, FileType: x.FileType, IsContent: "N", IsThumb: "N", UseYn: "Y"
                                    };
                                });
                                if (f1 != void 0 && f1.data.files && f1.data.files.length > 0) {
                                    var files = f1.data.files.map(function (x) {
                                        return {
                                            FileSeq: "", FileNm: x.name, FilePath: x.filePath, FileSize: x.size, FileType: x.mimeType, IsContent: "N", IsThumb: "N", UseYn: "Y"
                                        };
                                    });
                                    ff1 = ff1.concat(files, ofile);
                                }
                                else {
                                    ff1 = ofile;
                                }
                            };
                            // 섬네일
                            if (state.defaults.UseThumbImg === "Y") {
                                var f2 = await fileUploader2.value.uploadFiles();
                                var ofile = state.thumbImg.map((x) => {
                                    return {
                                        FileSeq: x.FileSeq, FileNm: x.FileNm, FilePath: x.FilePath, FileSize: x.FileSize, FileType: x.FileType, IsContent: "N", IsThumb: "Y", UseYn: "Y"
                                    };
                                });
                                if (f2 != void 0 && f2.data.files && f2.data.files.length > 0) {
                                    var files = f2.data.files.map(function (x) {
                                        return {
                                            FileSeq: "", FileNm: x.name, FilePath: x.filePath, FileSize: x.size, FileType: x.mimeType, IsContent: "N", IsThumb: "Y", UseYn: "Y"
                                        };
                                    });
                                    ff2 = ff2.concat(files, ofile);
                                }
                                else {
                                    ff2 = ofile;
                                }
                            };

                            ff = ff.concat(ff1, ff2);
                            ff = JSON.stringify(ff);

                            let zf = (res) => alertManager.alert.success("저장되었습니다.").then(methods.board.list);
                            notice.saveBoardContent(JSON.stringify(state.data), ff).then(zf);
                        }
                    };
                    alertManager.confirm.question("저장확인", "저장 하시겠습니까?").then(sf);
                },
                /**
                 * # 삭제
                 */
                rmve: () => {
                    var sf = function (x) {
                        if (x) {
                            let zf = res => alertManager.alert.success("삭제되었습니다.").then((z) => { methods.board.list(); });
                            notice.deleteBoardContent(getParameter()).then(zf);
                        }
                    };
                    alertManager.confirm.question("삭제확인", "삭제하시겠습니까?").then(sf);
                }
            }
        };
        const eventHandler = {
            codeListChange: () =>{
                state.data.FORM_NO = "";
                if(state.data.CodeVal.includes("CD021"))
                {
                    let code = state.data.CodeVal != 'CD021003' ? state.data.CodeVal : ''
                    recForm.getRecForms('Y', code).then((result) => {
                        state.FormList = result.Table.rows;
                    });
                }
            }
            // state.data.CodeVal" @change="eventHandler.codeListChange()"
            // state.FormList = result.forEach(res => {if(res === )})
        }
        watch(() => state.data.UseYn, (newValue, oldValue) => {
            // [as-is same] 사용여부 체크시 메인한줄공지 비활성화
            if (!newValue) state.data.SoloNotice = false;
        });
        watch(() => state.data.IsAnnounce, (newValue, oldValue) => {
            // [as-is same] 상단글고정 체크시 공지사항날짜 활성화
            if (!newValue) state.data.StartAnnounceDt = null, state.data.AnnounceDt = null;
        });
        watch(() => state.data.Perpetual, (newValue, oldValue) => {
            // [as-is same] 영구게시 체크시 영구게시 날자 비활성화
            if (newValue) state.data.StartpiredDt = null, state.data.ExpiredDt = null, state.data.StartpiredDtYmd = null, state.data.StartpiredDtHm = null, state.data.ExpiredDtYmd = null, state.data.ExpiredDtHm = null;
        });
        onMounted(() => {
            notice.getBoardInfo(_vrt.query.boardcategory).then((res) => {
                if (res) {
                    state.defaults = res;
                    common.getCodeInfoByParentCode(state.defaults.Code).then(res => {
                        if (res != void 0) {
                            state.codes = state.codes.concat(res);
                            if(state.defaults.Code == '0') state.data.CodeVal = "";
                            else state.data.CodeVal = state.codes[0].CodeVal;

                            if (_vrt.query.Post === "U") {
                                notice.getNoticeDetail(getParameter()).then((res) => {
                                    res.articles.IsAnnounce = res.articles.IsAnnounce === "Y" ? true : false;
                                    res.articles.SoloNotice = res.articles.SoloNotice === "Y" ? true : false;
                                    res.articles.UseYn = res.articles.UseYn === "Y" ? true : false;
                                    res.articles.Perpetual = res.articles.ExpiredDt !== null ? false : true;

                                    if (res.articles.StartpiredDt !== null) {
                                        let arr = res.articles.StartpiredDt.split(' ');
                                        res.articles.StartpiredDtYmd = arr[0], res.articles.StartpiredDtHm = arr[1];
                                    }
                                    if (res.articles.ExpiredDt !== null) {
                                        let arr = res.articles.ExpiredDt.split(' ');
                                        res.articles.ExpiredDtYmd = arr[0], res.articles.ExpiredDtHm = arr[1];
                                    }

                                    state.data = Object.assign(state.data, res.articles);
                                    state.Content = state.data.Content;
                                    state.attFiles = res.attFiles;
                                    state.thumbImg = res.thumbImg;

                                    // 채용타입이 상시일 경우
                                    let applyForm = state.data.CodeVal === 'CD021003' ? '' : state.data.CodeVal

                                    recForm.getRecForms('Y', applyForm).then((result) => {

                                        state.FormList = result.Table.rows;
                                    });
                                });
                            }

                            recForm.getRecForms('Y', state.data.CodeVal).then((result) => {
                                state.FormList = result.Table.rows;
                            });
                        }
                    });
                }
            });
        });
        return { state, store, methods, eventHandler, v$, smartEditor, fileUploader1, fileUploader2 }
    }
});
</script>